import React from 'react';
import { EditableLabel } from '../../Label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';

import './index.scss';


const GroupHeader = ({ groups, current, onGroupClick, onGroupRename, onGroupCreated }) => {
    const Plus = () => <span className="icon clickable flex-centered" onClick={() => onGroupCreated()}><FontAwesomeIcon icon={faPlus} /></span>

    return (
        <div className="message-group-header">
            <Plus />
            {
                groups && groups.map(groupElement => (
                    <EditableLabel
                        icon={Plus}
                        key={groupElement.id}
                        text={groupElement.name}
                        onChange={(val) => onGroupRename({ ...groupElement, name: val })}
                        onClick={() => onGroupClick(groupElement)}
                        isFocused={current && current.id === groupElement.id}
                        className={`message-group-header-group clickable ${current && current.id === groupElement.id ? 'active' : ''}`}>
                    </EditableLabel>
                ))
            }

        </div>
    )
};

export default GroupHeader;
