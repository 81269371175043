import React, { useState } from 'react';

import { compose } from 'recompose';
import {
    faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { readString } from 'react-papaparse';
import lodash from 'lodash';

import { withTranslation } from 'react-i18next';
import { withFirebase } from '../Firebase';
import { saveTable } from '../../service/http';

import { stopEvent } from '../../utils/common';


import './index.scss';

const TableForm = ({ t, user }) => {
    const [transactions, setTransactions] = useState([]);
    const [name, setName] = useState('New Period');
    const [csv, setCsv] = useState();

    const convert = () => {
        const result = readString(csv, {
            header: false
        });
        setTransactions(result.data);
    }
    const save = () => {
        let i = 0;
        if (transactions[0].length === 1) transactions.shift();
        const data = lodash.keyBy(transactions, (o) => i++);

        saveTable(user, { name, data });
    }

    const [editing, setEditing] = useState();

    return (
        <div className="table-form">
            { !editing && (
                <div>
                    <button onClick={() => setEditing(!editing)} type="submit" className="btn btn-primary">
                        {t('table.import')}
                    </button>
                </div>
            )}
            {
                editing && <div className="table-form-body">
                    <span className="name clickable" >{name}<span className="icon" ><FontAwesomeIcon icon={faEdit} /></span></span>
                    {
                        transactions.length === 0 && (<div>
                            <textarea type="textarea" onChange={(event) => setCsv(event.target.value)} />
                            <div className="btn btn-secondary" className="form-controlls">
                                <button onClick={convert} type="submit" className="btn btn-secondary">
                                    {t('table.parse')}
                                </button>
                                <a href="#" onClick={(event) => {
                                    stopEvent(event);
                                    setEditing(!editing)
                                }}>{t('table.close')}</a>

                            </div>
                        </div>)
                    }
                    {<table>
                        <tbody>
                            {

                                transactions.map((transaction, index) => {
                                    if (!index) return;

                                    return (<tr key={`${index}`}>
                                        {
                                            transaction.map((column, j) => <td key={`${index}-${j}`}>{column}</td>)
                                        }
                                    </tr>);

                                })

                            }
                        </tbody>
                    </table>}
                    {
                        transactions.length > 0 && (<div>
                            <button onClick={save} type="submit" className="btn btn-primary">
                                {t('table.save')}
                            </button>
                        </div>)
                    }
                </div>
            }
        </div>

    )
}


export default compose(
    withFirebase,
    withTranslation()
)(TableForm);



