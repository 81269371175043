import React from 'react';

import './index.scss';

const Body = ({ children, className }) => {

    return (
    <div className={`table-body y-scrollable ${className}`}>
        {children}
    </div>
)}

export default Body;



