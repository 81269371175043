import React, { useEffect, useState, useRef } from 'react';

import lodash from 'lodash';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import Toggle from 'react-toggle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFile,
    faPlus
} from '@fortawesome/free-solid-svg-icons';

import SplitableTable from '../SplitableTable';
import Part from '../SplitableTable/Part/index';
import Message from '../Message';
import SimpleMessage from '../SimpleMessage';
import Badge from '../Badge';


import { updateMessage, fetchAttachment, fetchNotesForMessage, saveGroup, updateEntity, fetchMessage } from '../../service/http';
import { downloadAttachment, stopEvent } from '../../utils/common';
import { FORMAT, MONTH_YEAR_FORMAT } from '../../constants/common';

import './index.scss';
import Loader from '../Loader';
import Header from '../SplitableTable/Header';
import Body from '../SplitableTable/Body';
import MessageGroup from '../../components/MessageGroup';
import GroupHeader from '../MessageGroup/GroupHeader';

const MESSAGE_WIDGET = 'message';
const GROUPS_WIDGET = 'groups';

const MessagesTable = ({ t, messages, groups = [], isActivationMode, onConnectMessage, topLevel = true }) => {

    const [messageList, setMessageList] = useState(messages);
    const [groupsList, setGroupsList] = useState(groups);
    const [group, setGroup] = useState(groups[0]);
    const [currentMessage, setCurrentMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [editingMessage, setEditingMessage] = useState();
    const [messageLoading, setMessageLoading] = useState();
    const [isWithTagsOnly, setIsWithTagsOnly] = useState();
    const [activeWidget, setActiveWidget] = useState(MESSAGE_WIDGET);

    let latestMonth = 0;

    const addTag = async (amount, message) => {
        const value = (amount.target.value || '').trim();

        const { tags = [] } = message;

        if (!value || value === (tags.find(tag => tag === value))) {
            setEditingMessage();
            setMessageList(messages);
            return;
        }
        tags.push(value);

        await updateMessage(message, { tags });
        setEditingMessage();
        setMessageList([...messages]);
    }
    const removeTag = async (value, message) => {
        const { tags = [] } = message;

        if (!tags.find(tag => tag === value)) {
            setEditingMessage();
            return;
        }

        lodash.pull(tags, value);

        await updateMessage(message, { tags });
        setEditingMessage();
        setMessageList([...messages]);
    }
    const onStartEditing = async (event, message) => {
        stopEvent(event);
        setEditingMessage(message);
    };
    const onAttachmentClick = async (message, attachmentId, filename) => {
        const { attachment: { data } } = await fetchAttachment(message, { attachmentId });
        downloadAttachment({ data, filename });
    };
    const onAttachmentInMessageClick = async ({ attachmentId, filename }) => {
        onAttachmentClick(currentMessage, attachmentId, filename);
    };
    const onMessageClick = async (message) => {
        setCurrentMessage();
        setMessageLoading(true);
        try {
            const { notes } = await fetchNotesForMessage(message);
            const { message: { content }} = await fetchMessage(message.id);

            message.notes = notes;
            message.content = content;
            setCurrentMessage(message);
        } finally {
            setMessageLoading(false);

        }
    };

    const onGroupCreated = async (newGroup) => {
        if (!newGroup) newGroup = {
            name: 'New Group',
            messages: [],
        }
        const ret = await saveGroup(newGroup);
        groupsList.push(ret);
        const newGroups = [...groupsList];
        setGroup(ret);
        setGroupsList(newGroups);
    }
    const onGroupUpdate = async (updatedGroup) => {
        await updateEntity(updatedGroup, { name: updatedGroup.name, messages: updatedGroup.messages }, 'groups');
        setGroup(Object.assign({}, updatedGroup));
    }
    const onGroupRename = async (updatedGroup) => {
        await updateEntity(updatedGroup, { name: updatedGroup.name }, 'groups');
        const groupToRename = groupsList.find(({ id }) => id === updatedGroup.id);
        groupToRename.name = updatedGroup.name;
        setGroupsList([...groupsList]);
    }

    const onTagFilterToggle = (e) => {
        const value = e.target.checked;
        let filteredMessages = messages;

        if (value) {
            filteredMessages = messages.filter(message => message.tags && message.tags.length);
        }
        setIsWithTagsOnly(value);
        setMessageList(filteredMessages);
    }

    const onWidgetSwitch = (widget) => {
        setGroup(groups[0]);
        setActiveWidget(widget);
    }

    useEffect(() => {
        setMessageList(messages);
    }, [messages]);
    useEffect(() => {
        setGroupsList(groups);
    }, [groups]);

    const onDragStart = (ev) => {
        const { id, snippet, sender, timestamp, from } = messages.find(message => message.id === ev.target.id);
        ev.dataTransfer.setData('message', JSON.stringify({ id, snippet, sender, timestamp, from }));
    }

    return (
        <>
            { loading &&
                <div className="text-center">
                    <div className="spinner-grow spinner-grow-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            <SplitableTable topLevel={topLevel} className={`full-height`} >
                <Part isLeaf className="full-height shared-page-table scrollable">
                    <Header >
                        <label>
                            <Toggle
                                defaultChecked={isWithTagsOnly}
                                icons={false}
                                onChange={onTagFilterToggle} />
                            <span>{t('messages.tagsOnly')}</span>
                        </label>
                    </Header>
                    <Body>
                        {
                            messageList && messageList.map((message) => {
                                const { snippet, amount, timestamp, sender, from, subject, content, tags = [] } = message;
                                const attachments = content ? content.filter(element => !!element.attachment && element.attachment.filename).map(element => element.attachment) : [];
                                const isEditingCurrent = (editingMessage === message);
                                const clazz = message === currentMessage ? 'selected' : '';

                                const date = moment(timestamp);

                                const messageMonth = date.month();
                                const isNextMonth = latestMonth !== messageMonth;
                                latestMonth = isNextMonth ? messageMonth : latestMonth;


                                return (
                                    <div key={message.id}>
                                        {isNextMonth && <h2 >{date.format(MONTH_YEAR_FORMAT)}</h2>}
                                        {
                                            <div key={message.id}
                                                draggable="true"
                                                onDragStart={onDragStart}
                                                id={message.id}
                                                className={`message-row clickable ${clazz}`}
                                                onClick={() => onMessageClick(message)} >

                                                <div className="important sender ellipsis">{sender || from}</div>
                                                <div className="important subject ellipsis" >{subject}</div>
                                                <div className="main" >
                                                    <div className="text" >
                                                        <div className="snippet ellipsis">
                                                            {snippet}
                                                        </div>
                                                        {
                                                            amount && !tags.length && !isEditingCurrent && <div
                                                                onClick={(event) => onStartEditing(event, message)}
                                                                className="important amount">{amount}</div>
                                                        }
                                                        {
                                                            isEditingCurrent && <div className="important tags">
                                                                <input type="text"
                                                                    placeholder={'Enter a tag'}
                                                                    onClick={event => stopEvent(event)}
                                                                    onBlur={(event) => addTag(event, message)} />
                                                            </div>
                                                        }
                                                        {
                                                            !isEditingCurrent && <div className="tags">
                                                                <span className="tags-edit" onClick={(event) => onStartEditing(event, message)}><FontAwesomeIcon icon={faPlus} /></span>
                                                                {
                                                                    tags.map(tag => <Badge text={tag} size="md" onClose={(ev) => {
                                                                        stopEvent(ev);
                                                                        removeTag(tag, message);
                                                                    }} />)
                                                                }
                                                            </div>
                                                        }
                                                        <div className="date">{date.format(FORMAT)}</div>
                                                        <div className="three-dots"></div>

                                                    </div>
                                                    <div>
                                                        {
                                                            attachments.map(({ filename, attachmentId }) => <Badge
                                                                onClick={ev => {
                                                                    stopEvent(ev);
                                                                    onAttachmentClick(message, attachmentId, filename);
                                                                }}
                                                                text={filename}
                                                                key={attachmentId}
                                                                icon={() => <FontAwesomeIcon icon={faFile} />}
                                                            />)
                                                        }
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                )
                            })
                        }

                        {
                            (!messageList || !messageList.length) && <SimpleMessage text={t('messages.nomessages')} />
                        }
                    </Body>

                </Part>
                <Part isLeaf className="full-height" >
                    <Header isPopupEnabled onWidgetSwitch={onWidgetSwitch}>
                        {(activeWidget === GROUPS_WIDGET) && <GroupHeader
                            onGroupRename={onGroupRename}
                            groups={groupsList}
                            onGroupCreated={onGroupCreated}      
                            current={group}
                            onGroupClick={group => setGroup(group)} />}
                    </Header>
                    <Body>
                        {
                            currentMessage && (activeWidget === MESSAGE_WIDGET) && <Message
                                editable
                                connectable
                                isActivationMode
                                onAttachmentClick={onAttachmentInMessageClick}
                                onConnectMessage={onConnectMessage}
                                presentation="vertical"
                                message={currentMessage} />
                        }
                        {
                            messageLoading && (activeWidget === MESSAGE_WIDGET) && <Loader />
                        }
                        {
                            !currentMessage && !loading && (activeWidget === MESSAGE_WIDGET) && <div className="full-height flex-centered "><span>{t('messages.defaultText')}</span></div>
                        }
                        {
                            (activeWidget === GROUPS_WIDGET) && <MessageGroup onGroupCreated={onGroupCreated} onGroupUpdate={onGroupUpdate} isGrupped group={group} />
                        }
                        {
                            (activeWidget === GROUPS_WIDGET) && !group && <div className="full-height flex-centered "><span>{t('groups.nogroups')}</span></div>

                        }
                    </Body>
                </Part>
            </SplitableTable>

            <a id="download-attach" download="filename" />
        </>
    );
}

export default withTranslation()(MessagesTable);
