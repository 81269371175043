import React, { useState } from 'react';
import Popup from 'reactjs-popup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faWindowMinimize,
    faWindowMaximize,
} from '@fortawesome/free-solid-svg-icons';

import { withTranslation } from 'react-i18next';

import './index.scss';

const Header = ({ t, children, isControlEnabled, onControlToggle, className, onWidgetSwitch, isPopupEnabled }) => {
    const [ isMinimized, setIsMinimized] = useState();

    const onControlClick = () => {
        setIsMinimized(!isMinimized);
        onControlToggle(!isMinimized)
    }

    return (
        <div className={`table-header ${className}`}>

            <div className="table-header-content">
                {children}
            </div>
            {
                isControlEnabled && <span className="icon clickable">
                    <FontAwesomeIcon onClick={onControlClick} icon={isMinimized ?  faWindowMaximize : faWindowMinimize}/>
                </span>
            }
            {
                isPopupEnabled && <Popup
                    trigger={<div className="three-dots"></div>}
                    position="left top"
                    on="click"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    className="widget-selector"
                    arrow={false}
                >
                    <div className="menu">
                        <div className="menu-item" onClick={() => onWidgetSwitch('message')}> {t('widgets.message')}</div>
                        <div className="menu-item" onClick={() => onWidgetSwitch('groups')}> {t('widgets.groups')}</div>
                    </div>
                </Popup>
            }


        </div>
    )
}

export default withTranslation()(Header);



