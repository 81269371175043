import React from 'react';
import './style.scss';
import { withTranslation } from "react-i18next";

//TODO complete translations
const Footer = ({ t }) => (
    <div className="footer">

        <div className="footer-divider" />
        <div className="footer-contacts">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 footer-contacts-line">
                        <div className="d-flex flex-row">
                            <div className="footer-icon-container">
                                <img src={require('../../assets/imgs/icon_location.png')} className="img-fluid footer-icon" alt={''} />
                            </div>
                            <div className="d-flex flex-column">
                                <span>Vintergatan 16</span>
                                <span >115 47 Stockholm</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 footer-contacts-line">
                        <div className="d-flex flex-row">
                            <div className="footer-icon-container">
                                <img src={require('../../assets/imgs/icon_phone.png')} className="img-fluid footer-icon" alt={''} />
                            </div>
                            <span>+1 (0)00 000 00 00</span>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="footer-icon-container">
                                <img src={require('../../assets/imgs/icon_mail.png')} className="img-fluid footer-icon" alt={''} />
                            </div>
                            <span>hello@tbely.com</span>
                        </div>
                    </div>
                    <div className="col-md-3 footer-contacts-line" style={{ marginBottom: 32 }}>
                        <span>{t('footer.get_connected')}</span>
                        <div className="d-flex flex-row">
                            <div className="footer-social-icon-container">
                                <img src={require('../../assets/imgs/social_facebook.png')} className="img-fluid footer-social-icon" alt={''} />
                            </div>
                            <div className="footer-social-icon-container">
                                <img src={require('../../assets/imgs/social_twitter.png')} className="img-fluid footer-social-icon" alt={''} />
                            </div>
                            <div className="footer-social-icon-container">
                                <img src={require('../../assets/imgs/social_p.png')} className="img-fluid footer-social-icon" alt={''} />
                            </div>
                            <div className="footer-social-icon-container">
                                <img src={require('../../assets/imgs/social_google.png')} className="img-fluid footer-social-icon" alt={''} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 footer-contacts-line footer-contacts-line-logo ">
                        <div className="footer-logo-icon-container">
                            <img src={require('../../assets/imgs/logo_icon.png')} alt='' className='img-fluid' />
                        </div>
                        <span>Tbely</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default withTranslation()(Footer);