import React from 'react';

import './index.scss';

const SplitableTable = ({ children, className, direction = 'row', template = '1-to-1' }) => (
    <div className={`splitable-table row ${className} direction-${direction} template-${template}` }>
        {children}
    </div>
);

export default SplitableTable;



