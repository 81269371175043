import React, { useState, useRef } from 'react';

import { withTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';

import Message from '../../components/Message';

import './index.scss';




const PopupMessageContainer = ({ t, message, onClose }) => {


    return (
        <Popup open={!!message} className="popup-message-container" onClose={onClose} position="right center">
            <div className="popup-message-container-content-body">
                <Message message={message} />
            </div>
        </Popup>

    )
}

export default withTranslation()(PopupMessageContainer);