import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import qs from 'qs';

import ConnectionForm from '../../components/ConnectionForm';
import MessagesTable from '../../components/MessagesTable';
import PopupMessageContainer from '../../components/PopupMessageContainer';
import TableForm from '../../components/TableForm';
import TablesList from '../../components/TablesList';
import { TokenExpireError } from '../../errors';
import { withAuthorization } from '../../components/Session';
import { withFirebase } from '../../components/Firebase';
import Loader from '../../components/Loader';
import SplitableTable from '../../components/SplitableTable';
import Part from '../../components/SplitableTable/Part/index';

import { fetchLandingPage, updateConnectionsAndProperties } from '../../service/http';

//import landing from '../../mocks/landing.json';
import { ROW_COLOR, ROW_STATES } from '../../constants/common';


import './index.scss';
import SimpleMessage from '../../components/SimpleMessage';
import Header from '../../components/SplitableTable/Header';
import Body from '../../components/SplitableTable/Body';

const LandingPage = ({ t, i18n, user, firebase, location: { search } }) => {
    const code = qs.parse(search, { ignoreQueryPrefix: true }).code;

    const [tables, setTables] = useState([]);
    const [messages, setMessages] = useState([]);
    const [groups, setGroups] = useState([]);
    const [message, setMessage] = useState();
    const [template, setTemplate] = useState();
    const [table, setTable] = useState();
    const [index, setIndex] = useState();
    const [loading, setLoading] = useState();

    const fetchData = async () => {
        try {
            setLoading(true);
            const { tables, messages, groups } = await fetchLandingPage();
            //const { tables, messages } = landing.data;
            setTables(tables);
            setMessages(messages);
            setGroups(groups);
        } catch (err) {
            if (err instanceof TokenExpireError.default) return firebase.doSignOut();
            throw err;
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!user) return;
        fetchData();
    }, [user]);

    const onShowConnection = (messageId) => {
        const message = messages.find(message => message.id === messageId);
        setMessage(message);
    }

    const onConnectMessage = (message) => {
        if (!table) return;

        const { connections, properties } = table;
        connections[`${index}`] = message.id;
        properties[`${index}`] = { color: ROW_COLOR.GREEN, state: ROW_STATES.EMAIL };

        updateConnectionsAndProperties(table, connections, properties);
        setTable();
        setIndex();
    }

    const onDetachRow = (table, index) => {

        const { connections, properties } = table;
        delete connections[`${index}`];
        delete properties[`${index}`];

        updateConnectionsAndProperties(table, connections, properties);
    }

    const onActivateRow = (table, row, index) => {
        setTable(table);
        setIndex(index);
    }

    return (<>
        <Helmet>
            <title>{t('pages.landing.title')}</title>
        </Helmet>
        {
            (!user || (user && !user.label)) && <header className="container block">
                <ConnectionForm user={user} code={code} />
            </header>
        }

        <SplitableTable className="full-page container block" direction="column" template={template} >
            {user && <Part isLeaf className="full-height">
                <Header isControlEnabled onControlToggle={(minimized) => setTemplate(minimized ? '2-to-10' : '1-to-1')}>

                </Header>
                <Body>
                    <TableForm user={user} />
                    {
                        !tables.length && <SimpleMessage text={t('tables.notables')} />
                    }
                    <TablesList
                        user={user}
                        tables={tables}
                        onShowConnection={onShowConnection}
                        onActivateRow={onActivateRow}
                        onDetachRow={onDetachRow} />
                </Body>
            </Part>
            }
            {
                loading && <Loader />
            }
            <Part className="full-height">
                {user && <>
                    <MessagesTable
                        isActivationMode={index >= 0}
                        topLevel={false}
                        user={user}
                        messages={messages}
                        groups={groups}
                        onConnectMessage={onConnectMessage} />
                    {
                        message && <PopupMessageContainer message={message} onClose={() => onShowConnection()} />
                    }
                </>}
            </Part>
        </SplitableTable>
    </>
    )
}

const condition = authUser => true;

export default compose(
    withFirebase,
    withTranslation(),
    withAuthorization(condition),
)(LandingPage);