import React from 'react';

import './index.scss';


const NumberedLabel = ({ text, number }) => (
    <span className={`label label-numbered `} >
        <span className="number">{number}</span>
        <span className="text" dangerouslySetInnerHTML={{ __html: text }}></span>
    </span>
);

export default NumberedLabel;
