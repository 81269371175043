import React, { useState } from 'react';

import lodash from 'lodash';

import Badge from '../../Badge';


import './index.scss';


const SharedTableElement = ({ t, table, onRowSelect }) => {

    const { data, connections, properties, columnProperties } = table;

    const [rows, setRows] = useState(lodash.map(data));

    console.log(columnProperties);


    return (
        <div className="shared-table">
            <div className="shared-table-data">

                <table>
                    <tbody>
                        {
                            rows.map((row, i) => {

                                const badge = (properties[i] && properties[i].state && properties[i].state.toLowerCase());


                                return (<tr key={`${i}`}
                                    className={`clickable ${properties[i] && properties[i].color ? properties[i].color.toLowerCase() : ''}`}
                                    onClick={() => onRowSelect(row, properties[i], connections[i])}
                                >
                                    {
                                        row.map((column, j) => {
                                            const visible = !(columnProperties[j] && columnProperties[j].hidden);
                                            return visible && <td key={`${i}-${j}`}>{column}</td>;
                                        })
                                    }
                                    {
                                        <td>
                                            <div className="column-badge">
                                                {
                                                    badge && <Badge text={badge} size="md" />
                                                }
                                            </div>
                                        </td>
                                    }
                                </tr>)
                            })
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}


export default SharedTableElement;



