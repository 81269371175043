import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from 'reactjs-popup';


import {
    faTimes
} from '@fortawesome/free-solid-svg-icons';

import './index.scss';



const Badge = ({ mode = 'shallow', size = 'sm', className = '', onClose, icon: Icon, text, onClick }) => {



    const Content = (
        <span className={` badge ${mode} ${className} ${size} ${onClose ? 'closable' : ''}`} onClick={onClick}>
            {Icon && <span className="badge-icon"><Icon /></span>}
            <span className="badge-value ellipsis">{text}</span>
            { onClose && <span onClick={onClose} className="badge-close">
                <FontAwesomeIcon icon={faTimes} />
            </span>}
        </span>
    );

    return (<Popup
        trigger={open => (
            Content
        )}
        on={['hover', 'focus']}
        position="right center"
        closeOnDocumentClick
    >
        <span>{text}</span>
    </Popup>);
}

export default Badge;
