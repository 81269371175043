import { fetchAttachment } from '../service/http';

export const isExchangeClosed = (date = new Date()) => {


};

export const downloadAttachment =  (attachment) => {
  const {data, filename } = attachment;
  
  const dataBase64Rep = data.replace(/-/g, '+').replace(/_/g, '/');
  let dlnk = document.getElementById('download-attach');

  const url = 'data:application/octet-stream;base64,' + dataBase64Rep;
  dlnk.href = url;
  dlnk.download = filename;
  dlnk.click();
  URL.revokeObjectURL(url);
}

export const stopEvent = event => {
  event.preventDefault()
  event.stopPropagation();
}




