export const LANDING = '/';
export const MESSAGE = '/messages/:messageId';
export const SHARE = '/shares/:token';

export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const PORTFOLIOS = '/portfolios';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const BUY_SELL = '/buysell';
export const PURCHASE = '/purchase/:id';
export const COMPETITIONS = '/competitions';

export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const RULES = '/rules';
