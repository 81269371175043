
export const TRANSACTION_TYPE = {
  BUY: 'BUY',
  SELL: 'SELL'
};
export const ROW_COLOR = {
  RED: 'RED',
  GREEN: 'GREEN',
  GREY: 'GREY',
};
export const ROW_STATES = {
  OWN: 'OWN',
  PRIVATE: 'PRIVATE',
  RECEIPT: 'RECEIPT',
  EMAIL: 'EMAIL',
};


export const FORMAT = 'MMM Do hh:mm';
export const TIME_ONLY_FORMAT = 'hh:mm';
export const DATE_MONTH_FORMAT = 'DD MMM';
export const MONTH_YEAR_FORMAT = 'MMM yyyy';

export const HTTP_ERRORS = {
  UNAUTHORIZED : 'UNAUTHORIZED',
  NOT_FOUND : 'NOT_FOUND',
  BAD_REQUEST : 'BAD_REQUEST',
}
