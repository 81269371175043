import React, { useState, useRef } from 'react';

import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFile,
    faStickyNote,
    faLink,
} from '@fortawesome/free-solid-svg-icons';

import SplitableTable from '../SplitableTable';
import Part from '../SplitableTable/Part/index';
import Badge from '../Badge';


import { FORMAT } from '../../constants/common';
import { addNote } from '../../service/http';

import './index.scss';
import { stopEvent } from '../../utils/common';


const Message = ({ t, message, onAttachmentClick, onConnectMessage, editable, connectable, isActivationMode, presentation = 'horizontal' }) => {
    const { from, amount, date_timestamp, sender, subject, content, notes } = message;

    const [addingNote, setAddingNote] = useState(false);


    const notesExists = (notes && notes.length > 0);

    const attachments = content ? content.filter(element => !!element.attachment && element.attachment.filename).map(element => element.attachment) : [];
    const htmlContent = (content ? content.find(element => !!element.html) : null) || {};
    const textContent = (content ? content.find(element => !!element.text) : null) || {};

    const Content = ({ html, text }) => <>
        {
            html && <iframe srcDoc={html} sandbox="allow-same-origin"></iframe>
        }
        {
            text && !html && <p>{text}</p>
        }
    </>

    const MessageWithNotes = ({ children }) => {
        const textAreaEl = useRef(null);
        const onNoteSubmitted = async () => {
            const value = textAreaEl.current.value;
            await addNote(message, value);
            setAddingNote(false);
        };


        return <SplitableTable direction="column" className={`full-height ${presentation}`} template="auto-to-1" >
            <Part className="notes">
                {
                    addingNote && <div className="note-form-body">
                        <textarea ref={textAreaEl} />
                        <button className="btn btn-secondary btn-wide"
                            onClick={onNoteSubmitted}
                        >{t('mail.submitNote')}</button>

                    </div>
                }
                {
                    !addingNote && notes && notes.map(note => <div key={note.id} className="note" dangerouslySetInnerHTML={{__html: note.text}}></div>)
                }
            </Part>
            <Part className="">
                {children}
            </Part>
        </SplitableTable>
    }

    const onAddNoteClick = () => setAddingNote(true);

    const onConnectClick = (event) => {
        stopEvent(event);
        onConnectMessage(message);
    }

    return (
        <div className="message mail" >
            <div className="mail-header">
                <div className="mail-header-top">
                    <div className="info">
                        {
                            connectable && <span className={`btn btn-round ${isActivationMode ? 'btn-active' : 'btn-inactive'}`} onClick={onConnectClick}>
                                <FontAwesomeIcon icon={faLink} />
                            </span>
                        }

                        <span className="subject">{subject}</span>

                    </div>
                    <div className="meta">
                        <span className="sender">{sender} {from}</span>
                        <span>{moment(date_timestamp).format(FORMAT)}</span>
                    </div>

                </div>
                <div className="attachments">
                    {
                        attachments.map((attachment) => (
                            <Badge
                                className="clickable"
                                key={attachment.attachmentId}
                                text={attachment.filename}
                                onClick={() => onAttachmentClick(attachment)}
                                icon={() => <FontAwesomeIcon icon={faFile} />}
                            />

                        ))
                    }
                </div>

            </div>
            <div className="mail-body y-scrollable">
                {
                    editable && (!notes || !notes.length) && !addingNote && <>
                        <button className="btn btn-tertiary btn-icon" onClick={onAddNoteClick}>
                            <span className="icon"><FontAwesomeIcon icon={faStickyNote} /></span>
                            <span>{t('mail.addNote')}</span>
                        </button>
                        <Content html={htmlContent.html} text={textContent.text} />
                    </>
                }
                {
                    (!editable || notesExists || addingNote) && <MessageWithNotes notes={notes} t={t} editMode={addingNote} >
                        <Content html={htmlContent.html} text={textContent.text} />
                    </MessageWithNotes>
                }

            </div>
        </div>

    )
}

export default withTranslation()(Message);