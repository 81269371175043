import React, { useState } from 'react';

import { compose } from 'recompose';
import { useAlert } from 'react-alert';
import Popup from 'reactjs-popup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faShare,
    faLink,
    faThumbtack,
    faChevronCircleUp,
    faChevronCircleDown,
    faEye,
    faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import lodash from 'lodash';

import { withTranslation } from 'react-i18next';
import { withFirebase } from '../Firebase';

import { shareTable, updateTable, updateEntity, SHARE_URL } from '../../service/http';
import { ROW_COLOR, ROW_STATES } from '../../constants/common';
import { EditableLabel } from '../Label';

import Badge from '../Badge';
import { stopEvent } from '../../utils/common';

import './index.scss';


const TableElementComponent = ({ t, table, onShowConnection, onActivateRow, onDetachRow }) => {
    const alert = useAlert();
    const { data, name, connections, properties = {}, columnProperties } = table;
    const numberOfConnections = connections ? Object.keys(properties).length : 0;

    const [editing, setEditing] = useState();
    const [rows, setRows] = useState(lodash.map(data));
    const [connectionsMap, setConnections] = useState(connections);
    const [propertiesMap, setPropertiesMap] = useState(properties);
    const [columnPropertiesMap, setColumnPropertiesMap] = useState(columnProperties);
    const [selectedRow, setSelectedRow] = useState();
    const [activatedRow, setActivatedRow] = useState();
    const [tableName, setTableName] = useState(name);

    const onConnectionClick = (event, connection) => {
        stopEvent(event);
        onShowConnection(connection);
    }
    const onToggleColumnClick = async (event, index) => {
        stopEvent(event);

        const currentColumnProperties = columnPropertiesMap[index] || {};

        currentColumnProperties.hidden = !currentColumnProperties.hidden;

        columnProperties[index] = currentColumnProperties;

        await updateTable(table, { columnProperties });

        setColumnPropertiesMap(Object.assign({}, columnProperties));

    }
    const onPinClick = (event, row, index) => {
        stopEvent(event);
        onActivateRow(table, row, index);
        setActivatedRow(index);
    }

    const onTableRename = async (name) => {
        await updateEntity(table, { name }, 'tables');
        table.name = name;
        setTableName(name);
    }

    const onShare = async (event) => {
        stopEvent(event);

        const { token } = table.share || await shareTable(table);
        navigator.clipboard.writeText(`${SHARE_URL}/${token}`);
        alert.show(t('common.linkCopied'));
    }

    const onRowClick = (i) => setSelectedRow(i);

    const SelectorPupup = () => {
        const closeModal = () => setSelectedRow();

        const onButtonClick = async (state) => {
            const rowProperties = propertiesMap[selectedRow] || {};
            let newProperties = {};

            switch (state) {
                case 'receipt':
                    newProperties = { color: ROW_COLOR.GREEN, state: ROW_STATES.RECEIPT };
                    break;
                case 'private':
                    newProperties = { color: ROW_COLOR.RED, state: ROW_STATES.PRIVATE };
                    break;
                case 'own':
                    newProperties = { color: ROW_COLOR.GREY, state: ROW_STATES.OWN };
                    break;
                default:
            }

            const finalProperties = Object.assign(rowProperties, newProperties);
            propertiesMap[selectedRow] = finalProperties;
            await updateTable(table, { properties: propertiesMap });
            setPropertiesMap(Object.assign({}, propertiesMap));
            setSelectedRow();
        };

        return (
            <Popup open={selectedRow >= 0} className="properties-form" onClose={closeModal} position="right center">
                <div className="properties-form-body">
                    <button className="btn btn-secondary btn-wide"
                        onClick={() => onButtonClick('receipt')}
                    >{t('properties.receipt')}</button>
                </div>
                <div className="properties-form-body">
                    <button className="btn btn-secondary btn-wide"
                        onClick={() => onButtonClick('private')}
                    >{t('properties.private')}</button>
                </div>
                <div className="properties-form-body">
                    <button className="btn btn-secondary btn-wide"
                        onClick={() => onButtonClick('own')}
                    >{t('properties.own')}</button>
                </div>
                <div className="properties-form-body">
                    <button className="btn btn-primary btn-wide"
                        onClick={() => {
                            onDetachRow(table, selectedRow);
                            closeModal();
                        }}
                    >{t('table.detach')}</button>
                </div>

            </Popup>
        )
    };

    return (
        <div className={`table card ${!editing ? 'edit' : ''} `}>
            <div className="header clickable">
                <span className="icon " onClick={() => setEditing(!editing)}>
                    {
                        !editing && <FontAwesomeIcon size="lg" className="chevron" icon={faChevronCircleDown} />
                    }
                    {
                        editing && <FontAwesomeIcon size="lg" className="chevron" icon={faChevronCircleUp} />
                    }

                </span>
                <span className="name">
                    <EditableLabel
                        text={tableName}
                        onChange={(val) => onTableRename(val)}
                        onClick={() => setEditing(!editing)}
                        >
                    </EditableLabel>
                    <span className="icon" onClick={onShare}>
                        {!table.share &&
                            <FontAwesomeIcon icon={faShare} />
                        }
                        {table.share &&
                            <FontAwesomeIcon icon={faLink} />
                        }
                    </span>

                </span>

                <div className="statistics">
                    <span>{t('table.total')}</span>
                    <span className="important">{rows.length}</span>
                    <span>{t('table.left')}</span>
                    <span className="important">{rows.length - numberOfConnections}</span>
                </div>
            </div>

            { editing && <table>
                <tbody>
                    {
                        rows.map((row, i) => {
                            const connection = connectionsMap[`${i}`];
                            const color = (propertiesMap[i] && propertiesMap[i].color) ? propertiesMap[i].color.toLowerCase() : 'empty';
                            const badge = (propertiesMap[i] && propertiesMap[i].state && propertiesMap[i].state.toLowerCase());

                            return (<tr className={`clickable ${i === selectedRow ? 'selected' : ''} ${color}`} key={`${i}`}>
                                <td >
                                    <span className={`icon ${activatedRow === i ? 'active' : ''}`}>
                                        <FontAwesomeIcon size="lg" onClick={(ev) => onPinClick(ev, row, i)} className="chevron" icon={faThumbtack} />
                                    </span>
                                </td>

                                {
                                    row.map((column, j) => {
                                        const isShown = !(columnProperties[j] && columnProperties[j].hidden);
                                        const icon = isShown ? faEye : faEyeSlash;
                                        return (<td key={`${i}-${j}`}>
                                            {
                                                i === 0 && <div><span className="icon">
                                                    <FontAwesomeIcon size="lg" onClick={(ev) => onToggleColumnClick(ev, j)} icon={icon} />
                                                </span>
                                                </div>
                                            }


                                            <span>{column}</span>
                                        </td>);
                                    })
                                }
                                <td>
                                    {
                                        connection && <a href="" onClick={(event) => onConnectionClick(event, connection)}>{connection}</a>
                                    }

                                </td>
                                <td>
                                    <div className="column-badge">
                                        {
                                            badge && <Badge text={badge} size="md" />
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div onClick={() => onRowClick(i)} className="three-dots"></div>
                                </td>

                            </tr>)
                        })
                    }

                </tbody>
            </table>}
            <SelectorPupup />
        </div>
    )
}

const TableElement = compose(
    withFirebase,
    withTranslation()
)(TableElementComponent);
export default TableElement;



