import React from 'react';
import { withRouter, NavLink, Link } from 'react-router-dom';

import { SignOutItem } from '../SignOut';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import { Navbar } from "react-bootstrap";
import './style.scss';
import { withTranslation } from "react-i18next";

const Navigation = ({t, location}) => (
    <div id="top" className=" navigation">
        <div className="container">
            <Navbar expand="lg">
                <Link className='akt- navbar-brand' to={ROUTES.LANDING}><img src={require('../../assets/imgs/logo_icon.png')} alt='' className='img-fluid'/></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <AuthUserContext.Consumer>
                    {value => value.authUser ? <NavigationAuth t={t} location={location} user={value.authUser} /> : <NavigationNonAuth t={t} />}
                </AuthUserContext.Consumer>
            </Navbar>
        </div>
    </div>
);

const NavigationAuth = () => (
  
        <Navbar.Collapse id="basic-navbar-nav">
            <ul className='navbar-nav'>
              <li className='nav-item'><SignOutItem/></li>
            </ul>
        </Navbar.Collapse>
);

const NavigationNonAuth = ({t}) => (
    <Navbar.Collapse id="basic-navbar-nav">

    </Navbar.Collapse>
);

export default withTranslation()(withRouter(props => <Navigation {...props}/>));