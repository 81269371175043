import React, {useState, useEffect} from 'react';

import './index.scss';


const EditableLabel = ({ text, onChange, isFocused, onClick, className = ''}) => {
    const [isEditing, setIsEditing] = useState();
    const [textValue, setTextValue] = useState(text);
    const [isCurrentlyFocused, setIsFocused] = useState();


    useEffect(() => setTextValue(text), [text]);
    useEffect(() => setIsFocused(isFocused), [isFocused]);

    const onLabelClick = () => {
        if(!isCurrentlyFocused) {
            onClick();
            setIsFocused(true);
            return;
        }

        setIsEditing(true);
    }
    const onTextValueChange = (ev) => {
        const val = ev.target.value;
        setTextValue(val);
    }
    const onBlur = (ev) => {
        const val = ev.target.value;
        setIsEditing(false);
        if (val === text) return;
        onChange(val);
    }



    return (
        <span className={`label label-editable clickable ${className}`} onClick={onLabelClick} >
            {
                !isEditing && <div className="label-editable-text" >{text}</div>
            }
            {
                isEditing && <input ref={(el) => (el && el.focus())} type="text" value={textValue} onChange={onTextValueChange} onBlur={onBlur}/>
            }
        </span>
    )
}

export default EditableLabel;
