import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { fetchMessage, fetchAttachment } from '../../service/http';
import { downloadAttachment } from '../../utils/common';
import { withAuthorization } from '../../components/Session';
import  Message  from '../../components/Message';

import './index.scss';

const MessagePage = ({ t, i18n, user = null, location }) => {

    let { messageId } = useParams();

    const [message, setMessage] = useState([]);

    const onAttachmentClick = async ({filename, attachmentId}) => {
        const { data } = await fetchAttachment(user, message, { attachmentId });
        downloadAttachment({ data, filename })
    }

    const fetchData = async () => {
        try {
            const { message } = await fetchMessage( messageId);

            setMessage(message);
        } catch ({ status }) {
            if (status === 404) setMessage('connection.missing');
        }
    }

    useEffect(() => {
        if (!user) return;
        fetchData();

    }, [user]);

    return (<>

        <Helmet>
            <title>{t('pages.message.title')}</title>
        </Helmet>
        <div className="container page full-page">
            {
                message && <Message message={message} editable onAttachmentClick={onAttachmentClick}/>
            }
        </div>
        <a id="download-attach" download="filename" />

    </>
    )
}

const condition = authUser => true;

export default withAuthorization(condition)(withTranslation()(MessagePage));