import React from 'react';

import './index.scss';

const Part = ({ children, className = '', isLeaf }) => {

    return (
    <div className={`part ${className} ${isLeaf ? '' : 'no-gutter'}`}>
        {children}
    </div>
)}

export default Part;



