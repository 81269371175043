import React, { useState, useEffect } from 'react';

import moment from 'moment-timezone';
import lodash from 'lodash';

import { TIME_ONLY_FORMAT, DATE_MONTH_FORMAT } from '../../constants/common';

import './index.scss';
import { stopEvent } from '../../utils/common';



const MessageGroup = ({ group, onGroupCreated, onGroupUpdate, className = '', isGrupped }) => {
    const [groupObject, setGroup] = useState(group);
    const [messagesList, setMesagesList] = useState([]);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (!group) return;
        const sortedMessages = lodash.orderBy(group.messages, ['timestamp'], ['desc']);

        setMesagesList(sortedMessages);
        setGroup(group);
    }, [group]);

    const onMessageClick = (id) => window.open(`messages/${id}`, "_blank");

    const Message = ({ id, itemStyle, sender, from, snippet }) => <div onClick={() => onMessageClick(id)} className="message-group-table-messages-item clickable" style={itemStyle}>
        {(sender || from) && <div className="sender ellipsis">{sender || from}</div>}
        <span className="message-group-table-messages-item-snippet">{snippet}</span>
        <div className="three-dots"></div>
    </div>;

    const RegularTimeline = () => (<div className={`message-group ${className}`}>
        <div className="message-group-timeline">
            {
                messagesList.map(message => {

                    const { timestamp } = message;
                    const date = moment(timestamp);


                    return <div className="message-group-timeline-item">
                        <span className="time">{date.format(TIME_ONLY_FORMAT)}</span>
                        <span className="date">{date.format(DATE_MONTH_FORMAT)}</span>
                    </div>

                })
            }
        </div>
        <div className="message-group-messages">
            {
                messagesList.map(message => <Message  {...message} />)
            }
        </div>
    </div>);

    const fromMap = {};

    messagesList.forEach(({ from }) => fromMap[from] = from);

    const fromList = Object.values(fromMap);

    const gridTemplateAreas = messagesList.reduce((acc, { from, id }) => {
        const row = fromList.reduce((fAcc, fCurr) => fCurr === from ? `${fAcc} A${id} ` : `${fAcc} . `, '');
        return acc + `"${row}" `;
    }, '');
    const gridTemplateColumns = `repeat(${fromList.length}, minmax(0, 1fr))`;

    const style = {
        display: 'grid',
        gridTemplateAreas: gridTemplateAreas,
        gridTemplateColumns,
    };

    const sendersStyle = {
        gridTemplateColumns
    }

    const onDragOver = (ev) => {
        stopEvent(ev);
        if (isDragging) return;
        setIsDragging(true);
    };
    const onDragLeave = (ev) => {
        stopEvent(ev);
        setIsDragging(false);
    };
    const onDropCapture = (ev) => {
        stopEvent(ev);
        setIsDragging(false);
        const message = JSON.parse(ev.dataTransfer.getData('message'));

        if (group && group.messages.find( ({ id }) => id === message.id)) return;
        if (!groupObject) {
            const newGroupObject = {
                name : 'New group',
                messages : [ message ]
            };
            onGroupCreated(newGroupObject);
            return;
        }

        
        groupObject.messages.push(message);
        onGroupUpdate(groupObject);
    };


    const GroupedBySenderTimeline = () => (
        <div
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDropCapture={onDropCapture}
            className={`message-group ${isDragging ? 'active' : ''}  ${className}`}>
            <div className={`message-group-table grouped`}>

                <div style={sendersStyle} className="message-group-table-senders">
                    {
                        fromList.map(from => <div key={from} className="message-group-table-senders-sender ellipsis">{from}</div>)
                    }
                </div>
                <div className="message-group-table-timeline">
                    {
                        messagesList.map(message => {
                            const { timestamp } = message;
                            const date = moment(timestamp);

                            return <div key={timestamp} className="message-group-table-timeline-item">
                                <span className="time">{date.format(TIME_ONLY_FORMAT)}</span>
                                <span className="date">{date.format(DATE_MONTH_FORMAT)}</span>
                            </div>

                        })
                    }
                </div>
                <div style={style} className="message-group-table-messages">

                    {
                        messagesList.map(({ id, snippet }) => {
                            const itemStyle = {
                                gridArea: `A${id}`,
                            }
                            return <Message key={id} id={id} itemStyle={itemStyle} snippet={snippet} />
                        })
                    }
                </div>
            </div>
        </div>)

    return (
        <GroupedBySenderTimeline />
    )
}

export default MessageGroup;



