import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                async authUser => {
                    if (!condition(authUser)) {
                        this.props.history.push(ROUTES.LANDING);
                    }

                    if (authUser) {
                        const token = await authUser.getIdToken(true);
                        sessionStorage.setItem('token', token);
                    }
                    this.setState({ authUser: this.props.authUser })
                },
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {value =>
                        condition(value && value.authUser) ? <Component {...this.props} user={value.authUser} configuration={value.configuration} /> : null
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return compose(
        withRouter,
        withFirebase,
    )(WithAuthorization);
};

export default withAuthorization;