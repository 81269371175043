import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { TRANSACTION_TYPE } from "../../constants/common";
/*
const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};
*/


var config = {
    apiKey: "AIzaSyDKD5kIPkwhv30G8-gnazATWi16CBziSLg",
    authDomain: "inmailio.firebaseapp.com",
    projectId: "inmailio",
    storageBucket: "inmailio.appspot.com",
    messagingSenderId: "619791454062",
    appId: "1:619791454062:web:9557ff155182f7e55ebb7f",
    measurementId: "G-NPHPSRGH4Z"
  };

const COLLECTIONS = {
    USERS: 'users',
    CONFIGURATION: 'configuration',
    COMPETITIONS: 'competitions',
    PORTFOLIOS: 'portfolios',
    TRANSACTIONS: 'transactions',
    STOCKS: 'stocks',
    MARKETPLACES: 'marketplaces'
};

class Firebase {
    constructor() {
        app.initializeApp(config);
    
        this.auth = app.auth();
        this.db = app.firestore();
    }
    
    doCreateUserWithEmailAndPassword = (email, password, user) => {
        return this.auth.createUserWithEmailAndPassword(email, password).then(result => {
            if (result.user) {
                return this.db.collection(COLLECTIONS.USERS).add({...user, id: result.user.uid })
            }
            return result
        });
    };

    signInGoogle = () => {
        const provider = new app.auth.GoogleAuthProvider();
        return this.auth.signInWithPopup(provider);
    };
    
    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);
    
    doSignOut = () => this.auth.signOut();
    
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
    
    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);
}

export default Firebase;