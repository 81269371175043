import React from 'react';

import './index.scss';

const Loader = ({ className = ''}) => <div className={`text-center loader full-height ${className}`}>
    <div className="spinner-grow spinner-grow-sm" role="status">
        <span className="sr-only">Loading...</span>
    </div>
</div>;

export default Loader;
