import React, { useState, useEffect } from 'react';

import { compose } from 'recompose';
import { BadRequestError, NotFoundError } from '../../errors';

import { withTranslation } from 'react-i18next';
import { withFirebase } from '../Firebase';
import { NumberedLabel } from '../Label';

import { subscribe } from '../../service/http';
import TopBarProgress from "react-topbar-progress-indicator";

import './index.scss';

TopBarProgress.config({
    barColors: {
        "0": "#FF0000",
        "1.0": "#fff"
    },
    shadowBlur: 5
});

const ConnectionForm = ({ t, user, code, firebase }) => {

    const [message, setMessage] = useState();
    const [isSubscribed, setIsSubscribed] = useState();
    const [isSubscribing, setIsSubscribing] = useState(false);

    const onLogin = async event => {
        event.preventDefault();
        setIsSubscribing(true);
        try {
            await firebase.signInGoogle();
        } catch ({code, message}) {
            console.warn(message);
            setIsSubscribing(false);
        }
    };

    const subscribeUser = async () => {
        try {
            setMessage();
            setIsSubscribing(true);
            const { url } = await subscribe({ code });
            if (url) { //show consent
                window.location.href = url;
                return;
            }

            setIsSubscribed(true);
            setIsSubscribing(false);

        } catch (err) {
            if (err instanceof BadRequestError.default) {
                setMessage('connect.codeInvalid');
            }
            if (err instanceof NotFoundError.default) {
                setMessage('connect.missing');
            }
            setIsSubscribing(false);
        }
    }

    useEffect(() => {
        if (!user) {
            setMessage();
            return
        };
        subscribeUser();
    }, [user]);

    return !isSubscribed ? (
        <div className={`connection-form ${isSubscribing ? 'disabled' : ''}`}>
            { isSubscribing && <TopBarProgress />}
            <div className="connection-form-container">
                <div className="texts">
                    {
                        !user && <div>
                            <NumberedLabel number="1" text={t('signup.step_1')} />
                        </div>

                    }
                    {
                        user && <>
                            <div>
                                <NumberedLabel number="1" text={t('connect.step_1')} />
                            </div>
                            <div>
                                <NumberedLabel number="2" text={t('connect.step_2')} />
                            </div>


                        </>
                    }
                </div>
                <div className="buttons">
                    {
                        !user && <button
                            onClick={onLogin}
                            type="submit"
                            disabled={isSubscribing}
                            className="btn btn-secondary btn-wide btn-icon"
                        >
                            <img src={require('../../assets/imgs/ico-google-tr.svg')} alt={''} />
                            {t('signin.submitWithGoogle')}
                        </button>
                    }
                    {
                        user && <button disabled={isSubscribing} className="btn btn-primary btn-wide"
                            type="submit"
                            onClick={subscribeUser}
                        >{t('signin.subscribe')}</button>
                    }

                </div>
            </div>
            <div>
                {
                    message && <div className="important">{t(message)}</div>
                }
            </div>
        </div>
    ) : null;
}


export default compose(
    withFirebase,
    withTranslation()
)(ConnectionForm);



