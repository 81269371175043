import {TokenExpireError, NotFoundError, BadRequestError} from '../errors';
import { HTTP_ERRORS } from '../constants/common';
const isDev =  (process.env.NODE_ENV === 'development');
const API_HOST = isDev ? 'http://localhost:5001/inmailio/us-central1' : 'https://us-central1-inmailio.cloudfunctions.net';
const PUBLIC_API_URL = `${API_HOST}/public`;
const PRIVATE_API_URL = `${API_HOST}/private`;
const HOST = isDev ? 'http://localhost:3000' : 'https://app.tbely.com';
const SHARE_URL = `${HOST}/shares`;

const getHeaders = () => ({
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Authorization': `Bearer ${sessionStorage.getItem('token')}`

})

const responseHandler =  async (response) => {
  const result = await response.json();
  const { error, data } = result;
    if (error) {
      switch( error.code ){
        case HTTP_ERRORS.UNAUTHORIZED: throw new TokenExpireError.default();
        case HTTP_ERRORS.NOT_FOUND: throw new NotFoundError.default();
        case HTTP_ERRORS.BAD_REQUEST: throw new BadRequestError.default();
        default:
          throw Error(error.message);
      }
    }

    return data;

}

export { SHARE_URL };

export const fetchSharedTable = (shareId) => {
  const headers = getHeaders();
  return fetch(PUBLIC_API_URL + `/shares/tables/${shareId}`, { method: 'GET', headers }).then(resp => resp.json()).then(res => res.data);
};
export const fetchSharedMessage = (token, messageId) => {
  const headers = getHeaders();
  return fetch(PUBLIC_API_URL + `/shares/tables/${token}/messages/${messageId}`, { method: 'GET', headers }).then(resp => resp.json()).then(res => res.data);
};
export const fetchSharedAttachment = (token, messageId, attachmentId) => {
  const headers = getHeaders();
  return fetch(PUBLIC_API_URL + `/shares/tables/${token}/messages/${messageId}/attachments/${attachmentId}`, { method: 'GET', headers }).then(resp => resp.json()).then(res => res.data);
};
export const fetchMessagesForUser = () => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/messages`, { method: 'GET', headers }).then(resp => resp.json()).then(res => res.data);
};
export const fetchCurrentUser = () => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me`, { method: 'GET', headers }).then(responseHandler);
};
export const fetchLandingPage = () => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/pages/landing`, { method: 'GET', headers }).then(responseHandler);
};
export const fetchNotesForMessage = (message) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/messages/${message.id}/notes`, { method: 'GET', headers }).then(responseHandler);
};
export const updateMessageAmount = (message, amount) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/messages/${message.id}`,
    { method: 'PUT', headers, body: JSON.stringify({ fields: { amount } }) })
    .then(resp => { return resp.json() }).then(res => res.data);
};
export const updateMessage = (message, fields) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/messages/${message.id}`,
    { method: 'PUT', headers, body: JSON.stringify({ fields: { ...fields } }) }).then(responseHandler);
};
export const updateEntity = (entity, fields, collection) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/${collection}/${entity.id}`,
    { method: 'PUT', headers, body: JSON.stringify({ fields: { ...fields } }) }).then(responseHandler);
};

export const addNote = (message, note) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/messages/${message.id}/note`,
    { method: 'PUT', headers, body: JSON.stringify({ note }) })
    .then(resp => { return resp.json() }).then(res => res.data);
};
export const updateConnections = (table, connections) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/tables/${table.id}`,
    { method: 'PUT', headers, body: JSON.stringify({ fields: { connections } }) })
    .then(resp => { return resp.json() }).then(res => res.data);
};
export const updateProperties = (table, properties) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/tables/${table.id}`,
    { method: 'PUT', headers, body: JSON.stringify({ fields: { properties } }) })
    .then(resp => { return resp.json() }).then(res => res.data);
};
export const updateTable = (table, fields) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/tables/${table.id}`,
    { method: 'PUT', headers, body: JSON.stringify({ fields: { ...fields } }) })
    .then(resp => { return resp.json() }).then(res => res.data);
};
export const updateConnectionsAndProperties = (table, connections, properties) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/tables/${table.id}`,
    { method: 'PUT', headers, body: JSON.stringify({ fields: { properties, connections } }) })
    .then(resp => { return resp.json() }).then(res => res.data);
};
export const shareTable = (table) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/tables/${table.id}/share`,
    { method: 'POST', headers }).then(resp => { return resp.json()}).then(res => res.data);
};

export const fetchAttachment = (message, attachment) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/messages/${message.id}/attachments/${attachment.attachmentId}`,
    { method: 'GET', headers }).then(responseHandler);
};
export const fetchMessage = (messageId) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/messages/${messageId}`,
    { method: 'GET', headers })
    .then(resp => {
      if (resp.status === 404) return Promise.reject({ status: 404 })
      return resp.json()
    }).then(res => {
      return res.data;
    });
};
export const saveTable = (user, table) => {
  const headers = getHeaders();
  fetch(PRIVATE_API_URL + `/users/me/tables`, { method: 'POST', headers, body: JSON.stringify({ table }) })
};
export const saveGroup = (group) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/groups`, { method: 'POST', headers, body: JSON.stringify({ group }) }).then(responseHandler);
};
export const fetchTablesForUser = (user) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/tables`, { method: 'GET', headers }).then(responseHandler);
};


export const subscribe = (data) => {
  const headers = getHeaders();
  return fetch(PRIVATE_API_URL + `/users/me/subscribe`, { method: 'POST', headers, body: JSON.stringify(data) }).then(responseHandler);
};


