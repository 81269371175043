import React from 'react';

import AuthUserContext from './context';
import { fetchCurrentUser } from '../../service/http';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            
            this.state = {
                authUser: null,
            };
        }
        
        componentDidMount() {
            this.props.firebase.auth.onIdTokenChanged((token) => {
              // reset authUser state when user logged out cause onAuthStateChanged don't executed
              if (!token) {
                this.setState({authUser: null, configuration: null})
              }
            });
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                async (authUser) => {
                    let user = null;
                    if (authUser) {
                        const token = await authUser.getIdToken(true);
                        sessionStorage.setItem('token', token);

                        user = await fetchCurrentUser();
                    }
                    this.setState({ authUser: user });
                },
            );
        }
        
        componentWillUnmount() {
            this.listener();
        }
        
        render() {
            return (
                <AuthUserContext.Provider
                  value={{
                      authUser: this.state.authUser,
                      configuration: this.state.configuration,
                  }}
                >
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }
    
    return withFirebase(WithAuthentication);
};

export default withAuthentication;