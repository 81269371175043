import React from 'react';

import { compose } from 'recompose';

import { TableElement } from '../TableElement';

import { withTranslation } from 'react-i18next';
import { withFirebase } from '../Firebase';

import './index.scss';



const TablesList = ({ t, user, tables, onShowConnection, onActivateRow, onDetachRow }) => {
    return (
        <div className="tables-list">
            {
                tables && tables.length > 0 && tables.map(table => <TableElement onActivateRow={onActivateRow}
                    onShowConnection={onShowConnection}
                    onDetachRow={onDetachRow}
                    user={user}
                    key={table.id}
                    table={table} />)
            }
        </div>
    )
}

export default compose(
    withFirebase,
    withTranslation()
)(TablesList);



