import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import Navigation from '../Navigation';
import LandingPage from '../../pages/Landing';
import MessagePage from '../../pages/MessagePage';
import SharedTable from '../../pages/SharedTable';
import Footer from '../Footer';


import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

import './styles.scss';
import NoMatch from "../NoMatch";

library.add(fab);

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

const App = () => (
  <Router>
    <Helmet>

      <meta name="viewport" content="width=device-width, initial-scale=1.0,user-scalable=no"></meta>
      <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="a06c93f0-905f-4467-8ef2-39974849ddb9" type="text/javascript" async></script>
    </Helmet>

    <Suspense className="body" fallback={<Loader />}>
      <Navigation />

      <Switch>
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route exact path={ROUTES.MESSAGE} component={MessagePage} />
        <Route exact path={ROUTES.SHARE} component={SharedTable} />
        <Route component={NoMatch} />
      </Switch>
      <Footer />
    </Suspense>

  </Router>
);

export default withAuthentication(App);