import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useAlert } from 'react-alert';
import qs from 'qs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faShare,
} from '@fortawesome/free-solid-svg-icons';

import { SharedTableElement } from '../../components/TableElement';
import Message from '../../components/Message';
import SplitableTable from '../../components/SplitableTable';
import Part from '../../components/SplitableTable/Part';
import Loader from '../../components/Loader';

import { fetchSharedTable, fetchSharedMessage, fetchSharedAttachment, SHARE_URL } from '../../service/http';

import { downloadAttachment } from '../../utils/common';
import './index.scss';




const SharedTable = ({ t, i18n, location: { search } }) => {

    const messageId = qs.parse(search, { ignoreQueryPrefix: true }).messageId;
    let { token } = useParams();
    const alert = useAlert();

    const [table, setTable] = useState();
    const [message, setMessage] = useState();
    const [rowState, setRowState] = useState();
    const [messageLoading, setMessageLoading] = useState(false);

    const fetchTable = async () => {
        const table = await fetchSharedTable(token);
        setTable(table);
    }

    const onRowSelect = async (row, properties, messageId) => {
        if (!messageId) {
            setMessage();
            const state = (properties && properties.state) || null;
            setRowState(state);
            return;
        };

        setMessageLoading(true);
        const { message } = await fetchSharedMessage(token, messageId);
        setMessage(message);
        setMessageLoading(false);

    }

    const onAttachmentClick = async ({ attachmentId, filename }) => {
        const { attachment: { data } } = await fetchSharedAttachment(token, message.id, attachmentId);
        downloadAttachment({ data, filename });
    };

    useEffect(() => {
        fetchTable();
    }, []);

    useEffect(() => {
        onRowSelect(null, null, messageId);
    }, [messageId])

    const onShare = () => {
        navigator.clipboard.writeText(`${SHARE_URL}/${token}${message ? '?messageId=' + message.id : ''}`);
        alert.show(t('common.linkCopied'));
    }

    const StateContent = () => <div className="full-height flex-centered">
        {
            rowState === 'OWN' && <span>{t('shared.own')}</span>
        }
        {
            rowState === 'PRIVATE' && <span>{t('shared.private')}</span>
        }
        {
            rowState === 'RECEIPT' && <span>{t('shared.receipt')}</span>
        }
        {
            !rowState && <span>{t('shared.empty')}</span>
        }
    </div>;

    return (<>

        <Helmet>
            <title>{t('pages.shared.title')}</title>
        </Helmet>
        <div className="container full-page shared-page">
            {
                !table &&  <Loader />
            }

            {
                table && <>
                    <div className="shared-page-header">
                        <h4>{table.name}</h4>
                        {message && <span className="clickable share" onClick={onShare}><FontAwesomeIcon icon={faShare} /></span>}
                    </div>
                    <SplitableTable className="full-height"  >
                        <Part size={8} className="full-height shared-page-table">
                            <SharedTableElement onRowSelect={onRowSelect} table={table} />
                        </Part>
                        <Part size={4}>
                            {
                                !messageLoading && message && <Message onAttachmentClick={onAttachmentClick} presentation="vertical" message={message} />
                            }
                            {
                                !messageLoading && !message && <StateContent />
                            }

                            {
                                messageLoading && <Loader className="state" />
                            }
                        </Part>
                    </SplitableTable>
                </>
            }
            <a id="download-attach" download="filename" />

        </div>
    </>
    )
}


export default (withTranslation()(SharedTable));